import * as React from 'react';
import './styles.scss';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, CardHeader, AvatarGroup, Avatar, Tooltip } from '@mui/material';
import moment from "moment";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Link } from "react-router-dom";
import 'moment/locale/pl';

moment.locale('pl');

function returnLenghtBetweenDates(startDate, endDate) {
    const eventLength = endDate.diff(startDate, 'days')+1;
    return `${eventLength} ${eventLength !== 1 ? 'dni' : 'dzień'}`;
}

export default function eventCard(props) {
    const { eventName, eventCity, province, startDate, country, endDate, facebookEventUrl, tags, category, img, linkDestination, status, infoLink, eventHash } = props.data.attributes;
    const imgUrlResized = img ? `https://res.cloudinary.com/dym1ldlty/image/upload/b_auto:predominant_gradient:2:diagonal_desc,c_pad,h_200,w_250/v1676127178/test/${img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    const formattedStartDate = moment(startDate, "YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");
    const eventLenght = returnLenghtBetweenDates(formattedStartDate, formattedEndDate);

    const id = props.data.id;
    const eventDetailsUrl = `/wydarzenie/${id}/${eventHash}`

    return (
        <Card variant="outlined" sx={{ maxWidth: 250, minWidth: '250px', m: '10px 20px', borderRadius: '5px', border: 0, paddingBottom: '10px', marginBottom: '40px', background: 'none' }}>
            <CardActionArea
                component = {Link}
                to ={ eventDetailsUrl }
            >
                <CardHeader
                    sx={{ padding: 0, paddingBottom: '10px', marginTop: '13px', height: '38px' }}
                    avatar={
                        <Tooltip title="Dodane przez Administratora Mootoz" placement="top">
                            <AvatarGroup max={3}  >
                                <Avatar sx={{ height: '25px', width: '25px' }} alt="Remy Sharp" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg" />
                            </AvatarGroup>
                        </Tooltip>
                    }
                    // action={
                    // <IconButton aria-label="settings">
                    //     <MoreVertIcon />
                    // </IconButton>
                    // }
                    title={ eventName }
                    // subheader={formattedStartDate.format('DD MMMM YYYY')}
                />
                <CardMedia
                    component="img"
                    height="200"
                    width="250"
                    image={imgUrlResized}
                    alt="green iguana"
                    sx={{ borderRadius: '5px' }}
                />
                <CardContent  sx={{ padding: 0, paddingTop: '10px' }}>
                    {/* <Typography gutterBottom component="div">
                        { eventName }
                    </Typography> */}
                    
                    <div className='location-container'>
                        <MapOutlinedIcon sx={{ mr: '5px', color: 'tomato', width: '18px' }}/>
                        <Typography variant="body2" sx={{ mt: '3px' }}>
                            <b>{ eventCity }</b>, { province?.data?.attributes.provinceName } { country?.data?.attributes.countryName }
                        </Typography>
                    </div>

                    <div className='location-container'>
                        <EventAvailableOutlinedIcon sx={{ mr: '5px', color: 'tomato', width: '18px' }}/>
                        <Typography variant="body2" sx={{ mt: '3px' }}>
                            <b>{ formattedStartDate.date() } { formattedStartDate.format('MMMM') } { formattedStartDate.format('YYYY') }</b> ∙ {eventLenght}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}