import 'pages/styles.scss';
import React from 'react';

class CookiePolicy extends React.Component {
    render() {
        return (
            <div className="pageContainer add-event">
                <h3>Polityka plików cookie</h3>

                <h4>Co to są pliki cookies?</h4>
                <p>
                    Pliki cookies tzw „ciasteczka” to niewielkie pliki, które należy rozumieć jako dane informatyczne,
                    zapisywane i przechowywane na Twoim urządzeniu podczas, gdy odwiedzasz różne strony WWW.    
                </p>

                <p>Pliki cookies zazwyczaj zawierają :</p>
                <ul>
                    <li>podstawowe informacje na temat nazwy strony WWW, z której pochodzą,</li>
                    <li>„długość życia” ciasteczka – czyli jaki ustawiony jest czas jego istnienia na Twoim urządzeniu,</li>
                    <li>losowo wygenerowany unikalny numer</li>
                </ul>

                <p>
                    Pliki cookies pozwalają na rozpoznanie urządzenia Użytkownika oraz poprawne wyświetlenie strony
                    internetowej dostosowanej do jego indywidualnych preferencji.
                </p>

                <h4>
                    Pliki cookies wykorzystujemy celem :
                </h4>
                <ul>
                    <li>
                    dostosowania zawartości strony www do preferencji użytkownika oraz optymalizacji
                    korzystania ze stron,
                    </li>
                    <li>
                    tworzenia anonimowych statystyk, które pozwalają zrozumieć w jaki sposób Użytkownik
                    korzysta ze strony, co umożliwia ulepszanie jej struktury i zawartości, z wyłączeniem
                    personalnej identyfikacji Użytkownika.
                    </li>
                </ul>

                <h4>Jakich plików cookies używamy?</h4>
                <p>
                    Nie zbieramy w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.
                </p>

                <p>Stosowane są dwa rodzaje plików „cookies” tj.</p>
                <ul>
                    <li>
                        sesyjne to pliki tymczasowe, które pozostają na urządzeniu użytkownika do wyłączenia oprogramowania (przeglądarki internetowej).
                    </li>
                    <li>
                        stałe to pliki pozostające na urządzeniu użytkownika przez czas określony w parametrach plików „cookies” albo do momentu ich ręcznego usunięcia przez użytkownika.
                    </li>
                </ul>

                <p>
                    Oprócz własnych plików cookies zamieszczane i wykorzystywane mogą być pliki cookies stron
                    trzecich, należące w szczególności do operatorów systemów analizujących i raportujących
                    oglądalność.
                </p>
                

                <h4>Pliki „cookies” stron trzecich, są wykorzystywane w następujących celach:</h4>
                <ul>
                    <li>
                        zbieranie danych statystycznych o popularności oraz użyteczności strony lub jej elementów;
                    </li>
                    <li>
                        zbieranie danych statystycznych o użytkownikach np. wiek, płeć, informacje demograficzne,
                        zainteresowania użytkowników za pomocą kodu Google Analytics (więcej informacji można
                        znaleźć <a href="https://support.google.com/analytics#topic=1008008" target="_blank" rel="noreferrer">tutaj</a>);
                    </li>
                </ul>

                <p>
                    Na ich podstawie tworzone są raporty zainteresowań oraz danych demograficznych. Na podstawie
                    tych danych oraz raportów nie jest możliwe zidentyfikowanie poszczególnych użytkowników.
                </p>
                <p>
                    Korzystamy z Google Analytics - usługi udostępnianej przez Google, która umożliwia analizę
                    oglądalności witryny internetowej. Google Analytics wykorzystuje cookies w celu umożliwienia witrynie
                    przeanalizowania sposobu w jaki użytkownik z niej korzysta.
                </p>

                <h4>Pliki cookies można podzielić na:</h4>

                <ul>
                    <li>
                        niezbędne - umożliwiające korzystanie z usług dostępnych w Serwisu, np. uwierzytelniające
                        pliki cookies wykorzystywane do usług wymagających uwierzytelniania
                    </li>
                    <li>
                        służące do zapewnienia bezpieczeństwa - np. wykorzystywane do wykrywania nadużyć w
                        zakresie uwierzytelniania;
                    </li>
                    <li>
                        wydajnościowe - umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych;
                    </li>
                    <li>
                        funkcjonalne - umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i
                        personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego
                        pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.
                    </li>
                </ul>

                <h4>USUWANIE PLIKÓW COOKIES</h4>
                <p>
                    Oprogramowanie (przeglądarki internetowe) domyślnie pozwala na umieszczanie plików cookies na
                    urządzeniu końcowym. Użytkownik może zmienić te ustawienia w taki sposób, aby blokować
                    automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o
                    ich każdorazowym przesłaniu na urządzenie użytkownika. Szczegółowe informacje o możliwości i
                    sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki
                    internetowej).
                </p>
                <p>
                    W przypadku, gdy Użytkownik nie dokonana zmiany domyślnych ustawień przeglądarki internetowej w
                    zakresie ustawień cookies, pliki te będą zamieszczone w urządzeniu końcowym Użytkownika.
                    Wyłączenie lub zmiana domyślnych ustawień plików cookies może spowodować utrudnienia w
                    korzystaniu z naszej strony.
                </p>

                <p>Ustawienia przechowywania plików cookies w przykładowych przeglądarkach:</p>
                <ul>
                    <li><a href="https://support.mozilla.org/pl/products/firefox/privacy-and-security" target="_blank" rel="noreferrer">Firefox</a></li>
                    <li><a href="https://help.opera.com/pl/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">Opera</a></li>
                    <li><a href="https://support.google.com/chrome/answer/114836?hl=pl" target="_blank" rel="noreferrer">Chrome</a></li>
                    <li><a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">Safari</a></li>
                    <li><a href="https://support.microsoft.com/pl-pl/topic/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noreferrer">Edge</a></li>
                </ul>
            </div>
        )
    }
}

export default CookiePolicy;