import './styles.scss';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const wodeshipsList = [
  {
    label: 'dolnośląskie',
    value: 1,
    name: 'group[34829][1]'
  },
  {
    label: 'kujawsko-pomorskie',
    value: 2,
    name: 'group[34829][2]'
  },
  {
    label: 'lubelskie',
    value: 4,
    name: 'group[34829][4]'
  },
  {
    label: 'lubuskie',
    value: 8,
    name: 'group[34829][8]'
  },
  {
    label: 'łódzkie',
    value: 16,
    name: 'group[34829][16]'
  },
  {
    label: 'małopolskie',
    value: 32,
    name: 'group[34829][32]'
  },
  {
    label: 'mazowieckie',
    value: 64,
    name: 'group[34829][64]'
  },
  {
    label: 'opolskie',
    value: 128,
    name: 'group[34829][128]'
  },
  {
    label: 'podkarpackie',
    value: 256,
    name: 'group[34829][256]'
  },
  {
    label: 'podlaskie',
    value: 512,
    name: 'group[34829][512]'
  },
  {
    label: 'pomorskie',
    value: 1024,
    name: 'group[34829][1024]'
  },
  {
    label: 'śląskie',
    value: 2048,
    name: 'group[34829][2048]'
  },
  {
    label: 'świętokrzyskie',
    value: 4096,
    name: 'group[34829][4096]'
  },
  {
    label: 'warmińsko-mazurskie',
    value: 8192,
    name: 'group[34829][8192]'
  },
  {
    label: 'wielkopolskie',
    value: 16384,
    name: 'group[34829][16384]'
  },
  {
    label: 'zachodniopomorskie',
    value: 32768,
    name: 'group[34829][32768]'
  }
];

class CookiePolicy extends React.Component {
  render() {
    return (
      <div className="pageContainer newsletter">
        <div className='newsletter__header'>
          <MailOutlineIcon sx={{color: 'tomato', fontSize: '150px', margin: 'auto'}} />
          <h1>Najlepsze wydarzenia motocyklowe bezpośrednio na Twoją skrzynkę email.</h1>
          <p>
              Chcesz otrzymywać cotygodniowy raport z podsumowaniem tego co będzie działo się w najbliższy weekend w wybranych przez Ciebie województwach ? Zloty, szkolenia, torowanie i wiele innych wydarzeń !
          </p>
          <p>Wystarczy że wypełnisz poniższy formularz</p>
        </div>
        

        <form onSubmit={this.handleSubmit} action="https://mootoz.us11.list-manage.com/subscribe/post?u=adc611d263881f9da4e584238&amp;id=76330550c5&amp;f_id=003eaae0f0" method="post" id="mc-embedded-subscribe-htmlForm" name="mc-embedded-subscribe-htmlForm">
          <TextField id="outlined-basic" label="Email"type="email" name="EMAIL" required variant="outlined" sx={{width: '100%'}}/>
          <TextField id="outlined-basic" label="Imię/Ksywka" name="NAME" variant="outlined" sx={{width: '100%', mt: '20px', mb: '30px'}}/>
          
          <h2>Wybierz interesujące Cię województwa</h2>


          <Grid container spacing={1} sx={{mb: '40px'}}>
            {wodeshipsList.map((element) => 
              <Grid md={4} xs={6}>
                <FormControlLabel control={<Checkbox />} label={element.label} value={element.value} name={element.name} />
              </Grid>
            )}
          </Grid >
          
          <p>* pola oznaczone gwiazdką są obowiązkowe</p>
          
          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
            <input type="text" name="b_adc611d263881f9da4e584238_76330550c5" tabindex="-1" value="" />
          </div>

          <Button type="submit" variant="contained" sx={{mt: '30px', width: '100%'}}>Zapisz mnie !</Button>
        </form>
      </div>
    )
  }
}

export default CookiePolicy;