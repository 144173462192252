import 'components/filters/styles.scss';
import { useHistory } from "react-router-dom";
import { useParams, Link } from "react-router-dom";
import { fetchTags, fetchCountries, fetchProvinces } from 'components/data/database';
import React, { useState, useEffect } from 'react';

import { TextField, MenuItem, Tabs, Tab } from '@mui/material';

export default function Search () {
    let { tags:tagsParam, category: categoryParam, country: countryParam, location: locationParam, page: pageParam } = useParams();

    let history = useHistory();
    const [tagss, setTags] = useState();
    const [countries, setCountires] = useState();
    const [allProvinces, setAllProvinces] = useState();
    const [provinces, setProvinces] = useState();

    const filterProvincesByCountryHash = (provinces, coutnrHash) => {
        return provinces.filter((province) => {
            return province.attributes.country.data.attributes.countryHash === coutnrHash;
        })
    }

    useEffect(() => {
        let allProvincesTemp = [];

        async function fetchData() {
            await fetchTags().then(tags => {
                setTags(tags.data);
            });
    
            await fetchCountries().then(tags => {
                setCountires(tags.data);
            });
    
            await fetchProvinces().then(tags => {
                allProvincesTemp = tags.data;
                setAllProvinces(tags.data);
            });

            setProvinces(filterProvincesByCountryHash(allProvincesTemp, countryParam))
        }

        fetchData();
    }, []);

    const changeCountries = (element) => {
        const newCountry = element.target.dataset.value;
        if (newCountry) {
            history.push(`/wydarzenia/${categoryParam}/${newCountry ? newCountry : countryParam}/wszedzie/${tagsParam}`)
            setProvinces(filterProvincesByCountryHash(allProvinces, newCountry))
        }
        
    }

    const changeProvince = (element) => {
        const newLocation = element.target.dataset.value;
        history.push(`/wydarzenia/${categoryParam}/${countryParam}/${newLocation ? newLocation :locationParam}/${tagsParam}`)
    }

    const changeCategory = (element) => {
        const newCategory = element.target.dataset.value;
        history.push(`/wydarzenia/${newCategory ? newCategory : categoryParam}/${countryParam}/${locationParam}/${tagsParam}`)
    }

    const changeTag = (element) => {
        const newTag = element.target.dataset.value;
        history.push(`/wydarzenia/${categoryParam}/${countryParam}/${locationParam}/${newTag ? newTag :tagsParam}`)
    }


    return (
        <div className='filters'>  
            <div className='filters__container'>
                <div className='filters__localization'>
                    <TextField
                            id="country-select"
                            select
                            label="Państwo"
                            defaultValue="wszedzie"
                            size="small"
                            onClick={changeCountries}
                            sx={{ width: '50%', mr: '15px' }}
                            value={countryParam}
                        >
                            {/* <MenuItem key="wszedzie" value="wszedzie">
                                    Wszędzie
                            </MenuItem> */}
                            {countries?.map((country) => (
                                <MenuItem key={country.attributes.countryHash} value={country.attributes.countryHash}>
                                    {country.attributes.countryName}
                                </MenuItem>
                            ))}
                    </TextField> 

                    <TextField
                        id="province=select"
                        select
                        label="Województwo"
                        defaultValue="wszedzie"
                        size="small"
                        onClick={changeProvince}
                        sx={{ width: '50%' }}
                        value={locationParam}
                    >
                        <MenuItem key="wszedzie" value="wszedzie">
                                Wszędzie
                        </MenuItem>
                            {provinces?.map((province) => {
                                return  (
                                <MenuItem key={province.attributes.provinceHash} value={province.attributes.provinceHash}>
                                    {province.attributes.provinceName}
                                </MenuItem>) 
                                }
                            )}
                    </TextField> 

                    {/* <TextField
                        id="category-select"
                        select
                        label="Typ"
                        defaultValue="wszedzie"
                        size="small"
                        onClick={changeCategory}
                        sx={{ width: '33%' }}
                        value={categoryParam}
                    >
                        <MenuItem key="moto&auto" value="moto&auto">
                                Wszystkie
                        </MenuItem>
                        <MenuItem key="moto" value="moto">
                                Moto
                        </MenuItem>
                        <MenuItem key="auto" value="auto">
                                Auto
                        </MenuItem>
                    </TextField>  */}
                   
                </div>
                <div className='filters__tags'>
                        <TextField
                            id="tag-select"
                            select
                            label="Kategoria"
                            defaultValue="wszystkie"
                            size="small"
                            onClick={changeTag}
                            sx={{ width: '100%', mr: '15px' }}
                            value={tagsParam}
                        >
                            <MenuItem key="wszystkie" value="wszystkie">
                                Wszystkie kategorie
                            </MenuItem>
                            {tagss?.map((tag) => {
                                return  (
                                <MenuItem key={tag.attributes.hash} value={tag.attributes.hash}>
                                    {tag.attributes.tagName}
                                </MenuItem>) 
                                }
                            )}
                        </TextField> 
                    </div>
                {/* <div className='filters__tags'>
                    <Tabs
                        value={tagsParam}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"
                        sx={{ maxWidth: 900 }}
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        <Tab 
                            label="Wszystkie kategorie"
                            component={Link}
                            value='wszystkie'
                            to={`/wydarzenia/${categoryParam}/${countryParam}/${locationParam}/wszystkie`}
                        />
                        { tagss?.map((tag, index) => {
                            return (
                                <Tab 
                                    value={tag.attributes.hash} 
                                    component={Link}
                                    label={tag.attributes.tagName}
                                    to={`/wydarzenia/${categoryParam}/${countryParam}/${locationParam}/${tag.attributes.hash}`}
                                    key={index}
                                />
                            )
                        })}
                    </Tabs>
                </div> */}
            </div>
        </div>
    )
    
}
