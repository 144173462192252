import 'components/cookieBanner/styles.scss';
import cookiesIcon from "assets/img/cookies-icon.svg"

export default function CookieBanner(props: any) {
    const acceptCookieBanner = () => {
        props.closeCookieBanner()
        localStorage.setItem('isCookieBannerAccepted', "true");
    }

    return (
        <>
        <div className="cookie-banner">
            <img className="cookie-banner__cookies-icon" src={cookiesIcon} alt="Cookies icon"/>
            <div className="cookie-banner__text-container">
            <span className="cookie-banner__header-text">Używamy plików cookies, aby ułatwić Ci korzystanie z naszego serwisu oraz do celów statystycznych.</span>
            Jeśli nie blokujesz tych plików, to zgadzasz się na ich użycie oraz zapisanie w pamięci urządzenia. Możesz samodzielnie zarządzać cookies, zmieniając ustawienia przeglądarki. Więcej informacji znajdziesz w <a className="cookie-banner_link" href="/polityka-plikow-cookies">polityce plików cookies</a>.
            </div>
            <div className="cookie-banner__buttons-container">
                <div onClick={() =>{acceptCookieBanner()}} className="cookie-banner__button cookie-banner__button--accept">Akceptuję</div>
                <a className="cookie-banner__button cookie-banner__button--more" href="/polityka-plikow-cookies">Pokaż więcej</a>
            </div>
            <div onClick={() =>{props.closeCookieBanner()}} className="cookie-banner__close-button"></div>
        </div>
        <div className="cookie-banner__cover"></div>
        </>
    )
}