import 'pages/styles.scss';
import './styles.scss';
import React, { useState, useEffect } from 'react';
import { fetchPromos, fetchEventsFiltered } from 'components/data/database';
import Seo from 'components/seo';
import {
    useParams
  } from "react-router-dom";
import moment from "moment";
import 'moment/locale/pl';
import { Chip, Avatar } from '@mui/material';
import EventCard from 'components/eventCard';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import FiltersHome from "components/filtersHome/";

import Button from '@mui/material/Button';

moment.locale('pl');

const categoryButtonStyling = { 
    mr: '10px', 
    color: '#d9534f', 
    borderColor: '#d9534f',
    mb: '10px'
}


const swiperBreakpoints = {
    0: {
        slidesPerView: 1,
        spaceBetween: 2,
    },
    350: {
    slidesPerView: 1.3,
    spaceBetween: 5,
    },
    680: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    880: {
        slidesPerView: 3,
        spaceBetween: 10,
    },
    966: {
        slidesPerView: 3.3,
        spaceBetween: 10,
    },
    1024: {
        slidesPerView: 4,
        spaceBetween: 10,
    },
    1380: {
        slidesPerView: 5,
        spaceBetween: 10,
    },
};
export default function OrganizerPage(props) {
    const [promos, setPromos] = useState();
    const today = moment().format("YYYY-MM-DD");

    useEffect(() => {
        const fetchData = async () =>{
            await fetchPromos(today).then(promo => {
                setPromos(promo.data);
            });
        }
        fetchData();
        
    }, []);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box>
                {children}
              </Box>
            )}
          </div>
        );
      }

      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className="home-page">
            <h1 className='homeh1'>Zloty motocyklowe, szkolenia, track days i wiele innych</h1>
            <FiltersHome />
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}

            >
                <SwiperSlide>
                    <a style={{display: 'flex', marginTop: '20px', justifyContent: "center"}} href='/wydarzenia/moto&auto/poland/wszedzie/wszystkie'>
                        <img 
                            className='slide__img'
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1707778462/homepage/banner01_ievrzv.webp"
                            alt='Mootoz'
                        />
                    </a>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <a style={{display: 'block', marginTop: '20px'}} href='/wydarzenie/283/xiii-festiwal-motocyklowy-bike-week-leba-2023'>
                        <img 
                            style={{maxWidth: '100%'}}
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1690234057/homepage/bike_week_leba_wrolqd.webp"
                            alt='Bile Week Łeba 2023'
                        />
                    </a>
                </SwiperSlide> */}
                {/* <SwiperSlide>
                    <div className='slide__container'>
                        <div className='slide__background'></div>
                        <div className='slide__background--line'></div>
                        <div className='slide__body'>
                            <div className='slide__header'>Wydarzenia motoryzacyjne</div>
                            <div className='slide__text'>Największa baza wydarzeń motocyklowych w Polsce</div>
                            <a href="/wydarzenia/moto&auto/poland/wszedzie/wszystkie" className="slide__cta">Odkrywaj</a>
                        </div>
                        <img 
                            className='slide__image'
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1676235736/homepage/banner_1300x300_gvgoez.webp"
                            alt='Mootoz'
                        />
                    </div>
                </SwiperSlide> */}
            </Swiper>

            {/* <div className='motoserceHeader tagName'>
                <div className='motoserceHeader__logo'>
                    <img alt='Motoserce logo' src='https://res.cloudinary.com/dym1ldlty/image/upload/bo_0px_solid_rgb:ffffff,c_scale,r_100,w_200/v1681225238/motoserce/motoserce2023logo_inimvn.jpg'/>
                </div>
                <div className='motoserce__container'>
                    <div className="tagNameContainer">
                        <h2>Motoserce 2024 - krew darem życia</h2>
                    </div>
                    <div className="tagNameContainer__description">
                        <p className='bigger'>Ogólnopolska Akcja Krwiodawstwa organizowana przez kluby zrzeszone w Kongresie Polskich Klubów Motocyklowych</p>
                        <Button type="href" size='large' href='/motoserce' sx={{color: '#d9534f', borderColor: '#d9534f', mt: '20px'}} variant="outlined">Zobacz wszystkie wydarzenia Motoserce</Button>
                    </div>
                </div>
            </div> */}

            <div className='top-events'>
                <Box className='top-events__tabs' sx={{ borderBottom: 1, borderColor: 'divider', m: '20px 5px' }}>
                    <Tabs 
                        value={value} 
                        onChange={handleChange} 
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        { ((promos?.length > 0)) ? promos.map((promo, index) => {
                            return (
                                <Tab label={promo.attributes.name} />
                                )
                        }) :  null }
                    </Tabs>
                </Box>

                { ((promos?.length > 0)) ? promos.map((promo, index) => {
                    return (
                        <TabPanel value={value} index={index}>
                            <Swiper
                                slidesPerView={1.3}
                                spaceBetween={3}
                                pagination={{
                                    clickable: true,
                                    dynamicBullets: true,
                                }}
                                breakpoints={swiperBreakpoints}
                                navigation={false}
                                modules={[Pagination, Navigation]}
                                className={`mySwiper${index}`}
                                style={{
                                    '--swiper-pagination-color': 'tomato',
                                }}
                            >
                                { ((promo.attributes?.events?.data.length > 0)) ? promo.attributes.events?.data?.map((event, index) => {
                                    return (
                                        <SwiperSlide>
                                        <EventCard 
                                            key={index+'i'}
                                            data={event}
                                        />
                                    </SwiperSlide>
                                    )
                            }) :  null }
                            </Swiper>
                        </TabPanel>
                        )
                }) :  null }
            </div>

            <div className='home-page__container'>
                <div>
                    <h3>Popularne kategorie</h3>
                    <Button type="href" href='/wydarzenia/moto&auto/wszedzie/wszedzie/gymkhana-motocyklowa' sx={categoryButtonStyling} variant="outlined">Gymkhana Motocyklowa</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/szkolenia' sx={categoryButtonStyling} variant="outlined">Szkolenia</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/zloty' sx={categoryButtonStyling} variant="outlined">Zloty</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/klasyki' sx={categoryButtonStyling} variant="outlined">Klasyki</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/targi' sx={categoryButtonStyling} variant="outlined">Targi</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/motoserce' sx={categoryButtonStyling} variant="outlined">Motoserce</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/rozpoczecie-sezonu' sx={categoryButtonStyling} variant="outlined">Rpozpoczęcie sezonu</Button>
                    <Button type="href" href='/wydarzenia/moto&auto/poland/wszedzie/wszystkie' sx={categoryButtonStyling} variant="outlined">Wszystkie wydarzenia</Button>
                </div>

                {/* <div>
                    <h3>Gymkhana Motocyklowa:</h3>
                    <Chip
                        label="Moto Gymkhana 9-te Mistrzostwa Europy 2023"
                        component="a"
                        href="/wydarzenie/4/moto-gymkhana-9-te-mistrzostwa-europy-2023"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="I Runda GymkhanaGP 2023 - Bolesławiec"
                        component="a"
                        href="/wydarzenie/7/i-runda-gymkhana-gp-2023-boleslawiec"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="II Runda GymkhanaGP 2023 - Warszawa"
                        component="a"
                        href="/wydarzenie/8/ii-runda-gymkhana-gp-2023-warszawa"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="III Runda GymkhanaGP 2023 - Szczecin"
                        component="a"
                        href="/wydarzenie/9/iii-runda-gymkhana-gp-2023-szczecin"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="IV Runda GymkhanaGP 2023 - Jaworzno"
                        component="a"
                        href="/wydarzenie/10/iv-runda-gymkhana-gp-2023-jaworzno"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="V Runda GymkhanaGP 2023 - Finał - Gorzów Wielkopolski"
                        component="a"
                        href="/wydarzenie/11/v-runda-gymkhana-gp-2023-final-gorzow-wielkopolski"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    /> */}
                    {/* <div>
                        <Avatar
                            alt="Remy Sharp"
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1675467579/test/adv_academy_logo_h3dwug.jpg"
                            sx={{ width: 70, height: 70, mt: '10px', mr: '10px' }}
                        />
                        <Avatar
                            alt="Remy Sharp"
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1675467579/test/adv_academy_logo_h3dwug.jpg"
                            sx={{ width: 70, height: 70, mt: '10px', mr: '10px' }}
                        />
                        <Avatar
                            alt="Remy Sharp"
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1675467579/test/adv_academy_logo_h3dwug.jpg"
                            sx={{ width: 70, height: 70, mt: '10px', mr: '10px' }}
                        />
                        <Avatar
                            alt="Remy Sharp"
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1675467579/test/adv_academy_logo_h3dwug.jpg"
                            sx={{ width: 70, height: 70, mt: '10px', mr: '10px' }}
                        />
                    </div> */}
                {/* </div> */}
                <div>
                    <h3>Proponowane wydarzenia motocyklowe:</h3>
                    <Chip
                        label="Zloty Motocyklowe Śląskie"
                        component="a"
                        href="/wydarzenia/moto/poland/slaskie/wszystkie"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="Zloty Motocyklowe Mazowieckie"
                        component="a"
                        href="/wydarzenia/moto/poland/mazowieckie/wszystkie"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="Zloty Motocyklowe Małopolskie"
                        component="a"
                        href="/wydarzenia/moto/poland/malopolskie/wszystkie"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                    <Chip
                        label="Zloty Motocyklowe w Polsce 2023"
                        component="a"
                        href="/wydarzenia/moto/poland/wszedzie/zloty"
                        variant="outlined"
                        clickable
                        sx={{ mr: '10px',mb: '10px' }}
                    />
                </div>
            </div>
        </div>
    )
}