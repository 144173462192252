import './loaderCovering.scss';

function Loader() {
    return (
        <div className={"loaderCovering"}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader;
