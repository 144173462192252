import React from 'react';
import './App.scss';
import EventList from "components/eventsList/eventList";
import Contact from "pages/contact/";
import Motoserce from "pages/static/motoserce";
import NotFound from "pages/notFound/";
import AddEvent from "pages/addEvent/";
import EventPage from "pages/eventPage/";
import NewsletterPage from "pages/newsletter/";
import CookiePolicy from "pages/cookiePolicy/";
import HomePage from "pages/homePage/";
import OrganizerPage from "pages/organizerPage/";
import TripsCategoryPage from "pages/trips/category";
import NewsPage from "pages/newsPage/";
import MenuTop from "components/menuTop/menuTop";
import Footer from "components/footer";
import CookieBanner from "components/cookieBanner";
import { fetchTags } from 'components/data/database';

import { Route, Switch, Redirect } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#d9534f',
      },
      secondary: {
        main: '#11cb5f',
      },
    }
  });

class App extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            areFiltersOpened: false,

            events: [],
            originalEvents: [],

            isLoading: true,
            isLoadingCoveringVisible: false,

            isCookieBannerVisible: false,

            filterLocalization: 'all',
            filterTags: [],
            filterCategory: 'all',
        }
    }

    hideCookieBanner = () => {
        this.setState({isCookieBannerVisible: false});
    }

    compareEventsForFilter (originalEvent, groupOfEvents) {
        return groupOfEvents.forEach(event => {
            return event.id === originalEvent.id;
        })
    }

    componentDidMount() {
        const isCookieBannerAccepted = Boolean(localStorage.getItem("isCookieBannerAccepted"));
        this.setState({isCookieBannerVisible: !isCookieBannerAccepted});


        fetchTags().then(tags => {
            this.setState({
                tags: tags.data
            });
        });
    }

    setAreFiltersOpenState = (areFiltersOpened) => {
        this.setState({
            areFiltersOpened,
        });
    }

    setGlobalState = (element) => {
        this.setState({...element})
    }

    render() {
        return (
            <div className="App">
                <ThemeProvider theme={theme}>
                <MenuTop />
                <div className="mainWrapper">
                    <div className="mainContent">
                         <React.Fragment>
                                <Switch>
                                    <Route exact path="/">
                                        {/* <Redirect to="/wydarzenia/moto&auto/poland/wszedzie/wszystkie" /> */}
                                        <HomePage />
                                    </Route>
                                    {/* <Route exact path="/news">
                                        <NewsPage />
                                    </Route> */}
                                    <Route exact path={["/wydarzenia/:category/:country/:location/:tags/:page?"]} render={ () => 
                                           <EventList /> 
                                        }
                                     />

                                    <Route exact path={["/wydarzenia/:country/:location/:tags/:page?"]} render={ () => 
                                           <EventList /> 
                                        }
                                    />

                                    <Route exact path={["/motoserce/:location?/:page?"]} 
                                        render={ () => 
                                           <Motoserce /> 
                                        }
                                     />
{/* 
                                    <Route exact path={["/wyprawy/wszedzie"]} render={ () => 
                                        <TripsCategoryPage /> 
                                    } /> */}

                                    <Route exact path={["/wydarzenie/:id/:eventHash?"]} render={ () => 
                                        <EventPage /> 
                                    } />

                                    <Route exact path={["/newsletter"]} render={ () => 
                                        <NewsletterPage /> 
                                    } />
{/* 
                                    <Route exact path={["/organizator/:id/:nameHash?"]} render={ () => 
                                        <OrganizerPage /> 
                                    } /> */}

                                    <Route exact path="/kontakt">  
                                        <Contact />
                                    </Route>
                                    
                                    <Route exact path="/dodaj-wydarzenie">
                                        <AddEvent/>
                                    </Route>
                                    <Route exact path="/polityka-plikow-cookies">
                                        <CookiePolicy />
                                    </Route>
                                    <Route path="/404">
                                        <NotFound />
                                    </Route>
                                    <Redirect to="/404" />
                                </Switch>
                            </React.Fragment>
                    </div>
                </div>
                <Footer/>
                {/* <Route  exact path={["/wydarzenia/:category/:location/:tags?"]}>
                    <FiltersMobileButton
                        setAreFiltersOpenState={this.setAreFiltersOpenState}
                        areFiltersOpened={this.state.areFiltersOpened}
                    />
                </Route> */}
                    
                
                {this.state.isCookieBannerVisible 
                    ? <CookieBanner 
                        closeCookieBanner={this.hideCookieBanner}
                        />
                    : null
                }
                 </ThemeProvider>
            </div>
        );
    }
}

export default App;
