import React, { useState } from 'react';
import './eventCard.scss';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, CardHeader, AvatarGroup, Avatar, Tooltip } from '@mui/material';
import moment from "moment";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from "react-router-dom";
import 'moment/locale/pl';

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

moment.locale('pl');

function returnLenghtBetweenDates(startDate, endDate) {
    const eventLength = endDate.diff(startDate, 'days')+1;
    return `${eventLength} ${eventLength !== 1 ? 'dni' : 'dzień'}`;
}

export default function EventCard(props) {
    const { eventName, eventCity, province, startDate, country, endDate, facebookEventUrl, tags, category, img, linkDestination, status, infoLink, eventHash } = props.data.attributes;
    // const imgUrlResized = img ? `https://res.cloudinary.com/dym1ldlty/image/upload/c_thumb,h_210,w_310/v1673302307/test/${img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    const imgUrlResized = img ? `https://res.cloudinary.com/dym1ldlty/image/upload/b_auto:predominant_gradient:2:diagonal_desc,c_pad,h_210,w_310/v1676127178/test/${img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    const imgUrlFull = img ? `https://res.cloudinary.com/dym1ldlty/image/upload/v1676127178/test/${img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    const formattedStartDate = moment(startDate, "YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");
    const eventLenght = returnLenghtBetweenDates(formattedStartDate, formattedEndDate);
    const [isPosterVisible, setPosterVisible] = useState(false);

    const id = props.data.id;
    const eventDetailsUrl = `/wydarzenie/${id}/${eventHash}`

    return (
        <Card sx={{ maxWidth: 310, m: 2, borderRadius: '3px', position: 'relative' }}>
            {isPosterVisible && <Lightbox allowRotate={false} image={imgUrlFull} title="Plakat" onClose={()=>{setPosterVisible(false)}} />}
            <Fab size="small" color="default" sx={{position: 'absolute', bottom: 120, right: 16, zIndex: 1}} aria-label="Powiększ plakat" onClick={()=>{setPosterVisible(true)}}>
                <ZoomInIcon />
            </Fab>
            
            <CardActionArea
                component = {Link}
                to ={ eventDetailsUrl }
            >
            <CardHeader
                avatar={
                    <Tooltip title="Dodane przez Admnistatora Mootoz" placement="top">
                        <AvatarGroup max={3}>
                            <Avatar alt="Remy Sharp" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg" />
                            {/* <Avatar alt="Travis Howard" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674070437/test/motoorkiestraLogo60_uc883x.jpg" />
                            <Avatar alt="Cindy Baker" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg" />
                            <Avatar alt="Agnes Walker" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg" />
                            <Avatar alt="Trevor Henderson" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg" /> */}
                        </AvatarGroup>
                        {/* <Avatar 
                            aria-label="recipe" 
                            src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg"
                        /> */}
                    </Tooltip>
                }
                // action={
                // <IconButton aria-label="settings">
                //     <MoreVertIcon />
                // </IconButton>
                // }
                title={ eventName }
                // subheader={formattedStartDate.format('DD MMMM YYYY')}
            />
                <CardMedia
                    component="img"
                    height="210"
                    width="320"
                    image={imgUrlResized}
                    alt="green iguana"
                />
                <CardContent>
                    {/* <Typography gutterBottom variant="h6" component="div" sx={{ minHeight: '65px' }}>
                        { eventName }
                    </Typography> */}
                    {/* <Chip icon={<EventAvailableOutlinedIcon />} label={formattedStartDate.format('DD MMMM YYYY')} variant="outlined" />
                    <Chip icon={<EventAvailableOutlinedIcon />} label={'test'} variant="outlined" /> */}
                    {/* <Typography variant="body2">

                        <EventAvailableOutlinedIcon color="primary" />{ formattedStartDate.date() } { formattedStartDate.format('MMMM') } { formattedStartDate.format('YYYY') }
                    </Typography> */}
                    
                    <div className='location-container'>
                        <MapOutlinedIcon color="primary" sx={{ mr: '5px' }}/>
                        <Typography variant="body2" sx={{ mt: '3px' }}>
                            <b>{ eventCity }</b>, { province?.data?.attributes.provinceName } { country?.data?.attributes.countryName }
                        </Typography>
                    </div>

                    <div className='location-container'>
                        <EventAvailableOutlinedIcon color="primary" sx={{ mr: '5px' }}/>
                        <Typography variant="body2" sx={{ mt: '3px' }}>
                            <b>{ formattedStartDate.date() } { formattedStartDate.format('MMMM') } { formattedStartDate.format('YYYY') }</b> ∙ {eventLenght}
                        </Typography>
                    </div>
                </CardContent>
            </CardActionArea>

            {/* <CardActions disableSpacing>
            { (tags?.data.length > 0) ? tags.data.map((tag, index) => {
                        return (
                            <Chip
                                label={tag.attributes.tagName}
                                component="a"
                                href="#basic-chip"
                                variant="outlined"
                                clickable
                                size="small"
                                sx={{ mb: '10px' }}
                            />
                        )
                    }) :  null } 
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
    
            </CardActions> */}
        </Card>
    );
}