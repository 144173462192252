// const fetchEvents = new Promise(function(resolve, reject) {

    
//     // fetch('https://mootoz-cms.herokuapp.com/api/events/')
//     fetch('http://192.168.68.113:1337/api/events2')
//     // fetch('https://mootoz.pl/api/getAllEventsFiltered.php')
//         .then((response) => {
//             return response.json();
//         })
//         .then((myJson) => {
//             resolve( myJson);
//         });
// })

// const strapiUrl = 'http://localhost:1337'
// const strapiUrl = 'http://192.168.68.113:1337'

const strapiUrl = 'https://mootoz-cms.herokuapp.com';

const fetchTagByHash = (tagHash) => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/tags?filters[hash][$eq]=${tagHash}`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchTags = () => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/tags`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchCountries = () => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/countries?populate=*`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchProvinces = () => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/provinces?populate=*&pagination[pageSize]=99`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchProvincesByCountryHash = (countryHash) => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/provinces?populate=*&filters[country][countryHash][$eq]=${countryHash}`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchEventByTagHash = (tagHash, page) => new Promise(function(resolve, reject) {
    const pageNumber = page ? page : 1;
    const url = tagHash ==='wszystkie' ? `${strapiUrl}/api/events?sort=startDate&pagination[page]=${pageNumber}&pagination[pageSize]=24` : `http://192.168.68.113:1337/api/events?sort=startDate&filters[tags][hash][$eq]=${tagHash}`;
    fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchEventsFiltered= ({tagHash, categoryHash, countyrHash, locationHash, page, today, pageSize}) => new Promise(function(resolve, reject) {
    const pageNumber = page ? page : 1;
    const categoryHashFilter = (categoryHash && categoryHash !== 'moto&auto') ? `&filters[categories][categoryHash][$eq]=${categoryHash}` : '';
    const tagHashFilter = (tagHash && tagHash !== 'wszystkie') ? `&filters[tags][hash][$eq]=${tagHash}` : '';
    const countryHashFilter = (countyrHash && countyrHash !== 'wszedzie') ? `&filters[country][countryHash][$eq]=${countyrHash}` : '';
    const locationHashFilter = (locationHash && locationHash !== 'wszedzie') ? `&filters[province][provinceHash][$eq]=${locationHash}` : '';
    const pageSizeFilter = pageSize ? `&pagination[pageSize]=${pageSize}` : '&pagination[pageSize]=24';

    const dateFilter = `&filters[endDate][$gte]=${today}`;

    const url = `${strapiUrl}/api/events?sort=startDate${dateFilter}${tagHashFilter}${countryHashFilter}${locationHashFilter}${categoryHashFilter}&pagination[page]=${pageNumber}${pageSizeFilter}&populate=*`;
    fetch(url)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchEvent = (eventId) => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/events/${eventId}?populate=*`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})


const fetchPromos = (filterDate) => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/promos?populate[events][sort]=startDate&populate[events][filters][endDate][$gte]=${filterDate}&populate[events][populate][1]=province`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchOrganizer = (organizerId) => new Promise(function(resolve, reject) {
    fetch(`${strapiUrl}/api/organizers/${organizerId}?populate=*`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

const fetchTrips = (minaDate, maxDate) => new Promise(function(resolve, reject) {
    const startDateFilter = minaDate ? `&filters[startDate][$gte]=${minaDate}` : '';
    const endDateFilter = maxDate ? `&filters[endDate][$lte]=${maxDate}` : '';


    fetch(`${strapiUrl}/api/trips?sort=startDate${startDateFilter}${endDateFilter}&pagination[pageSize]=100&populate=*`)
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            resolve( myJson);
        });
})

export { 
    fetchEvent, 
    fetchTagByHash, 
    fetchEventByTagHash, 
    fetchTags, 
    fetchCountries, 
    fetchProvincesByCountryHash, 
    fetchEventsFiltered, 
    fetchProvinces, 
    fetchOrganizer,
    fetchPromos,
    fetchTrips
};
