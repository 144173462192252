import 'pages/styles.scss';
import React from 'react';

class Contact extends React.Component {
    render() {
        return <div className="pageContainer add-event">
                <h3>Kontakt</h3>
                <p>Jeśli chcesz się z nami skontaktować napisz na adres:</p>
                <a href="mailto:kontakt@mootoz.pl">kontakt@mootoz.pl</a>
                <p>Facebook: <a href="https://www.facebook.com/MOOTOZPolska/">Mootoz Polska</a></p>
                <p>Słyszałaś/eś o jakimś ciekawym wydarzeniu lub jesteś organizatorem, daj nam koniecznie znać!</p>
                
                <p>W sprawie dodawania wydarzeń: <a href="mailto:dodaj@mootoz.pl">dodaj@mootoz.pl</a> lub <a href="/dodaj-wydarzenie">wypełnij formularz</a></p>
                <br/>
                <p>Naszym celem jest łączenie pasjonatów motoryzacji poprzez ułatwienie im wyszukiwania wydarzeń takich jak zloty, rajdy, premiery nowych modeli, torowanie i wiele innych.</p>
                <p style={{fontWeight: 400}}>Mootoz.pl - wydarzenia motoryzacyjne w jednym miejscu. <br/> Już nic ciekawego Cię nie ominie!</p>
            </div>
    }
}

export default Contact;