import 'pages/styles.scss';
import './styles.scss';
import React, { useState, useEffect } from 'react';
import { fetchOrganizer } from 'components/data/database';
import Seo from 'components/seo';
import {
    useParams
  } from "react-router-dom";
import moment from "moment";
import 'moment/locale/pl';
import EventCard from 'components/eventsList/eventCard.js';

moment.locale('pl');

export default function OrganizerPage(props) {
    const [organizer, setOrganizer] = useState([]);
    const imgUrlResized = organizer.img ? `https://res.cloudinary.com/dym1ldlty/image/upload/c_scale,h_250/v1673219437/test/${organizer.img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    const organizerLogoUrl = organizer.logo ? `https://res.cloudinary.com/dym1ldlty/image/upload/c_scale,h_300/v1677103766/organizers/${organizer.logo}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
   
    let { id } = useParams();

    useEffect(() => {
        const fetchData = async () =>{
            await fetchOrganizer(id).then(organizer => {
                setOrganizer(organizer.data.attributes);
                // document.title = `${event.data.attributes.eventName} - Mootoz.pl wszystkie wydarzenia mtororyzacyjne w jednym miejscu`;
            });
        }
        fetchData();
        
    }, []);

    return (
        <div className="organizer-page">
            <Seo 
                title={organizer.organizerName ? organizer.organizerName : 'Mootoz - Wydarzenia motoryzacyjne'}
                // description="test desctr"
                // url="url"
                type="events.event"
                image={imgUrlResized}
            />
            
            <div>
                <div>
                    <img src={organizerLogoUrl} alt={`${organizer.organizerName} logo`}/>
                </div>

                <div>
                    <div>
                        <h2>{organizer.organizerName}</h2>
                    </div>
                
                    <div>
                        {organizer.websiteUrl}
                    </div>
                    <div>
                        {organizer.facebookUrl}
                    </div>
                    <div>
                        Telefon: 602 387 564
                    </div>
                    <div>
                        E-mail: test@racingacademy.pl
                    </div>
                </div>
            </div>
            

            <div>
                {organizer.description}
            </div>

            <h3>Wydarzenia:</h3>
            <div className='organizer-page__events'>
                
                {organizer.events?.data.map((event, index) => {
                    return (
                        <EventCard 
                            key={index+'i'}
                            data={event}
                        />
                    )
                })}
            </div>
        </div>
    )
}
