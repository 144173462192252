import './styles.scss';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, CardHeader, Button, Avatar, Tooltip } from '@mui/material';
import moment from "moment";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Link } from "react-router-dom";
import 'moment/locale/pl';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import EventIcon from '@mui/icons-material/Event';
import DirectionsIcon from '@mui/icons-material/Directions';
import VerifiedIcon from '@mui/icons-material/Verified';

moment.locale('pl');

function returnLenghtBetweenDates(startDate, endDate) {
  const eventLength = endDate.diff(startDate, 'days')+1;
  return `${eventLength} ${eventLength !== 1 ? 'dni' : 'dzień'}`;
}

export default function eventCard(props) {
  const { name, startDate, organizer, endDate, listImage, eventHash } = props.data.attributes;
  const coverImageResized = listImage ? `https://res.cloudinary.com/dym1ldlty/image/upload/c_fill,h_180,q_90,w_318/v1677408852/trips/${listImage}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
  // const coverImageResized = listImage ? `https://res.cloudinary.com/dym1ldlty/image/upload/c_fill,h_190,q_90,w_380/v1677408852/trips/${listImage}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
  const organizerLogoReized = organizer?.data?.attributes?.logo ? ` https://res.cloudinary.com/dym1ldlty/image/upload/v1677355392/organizers/${organizer?.data?.attributes?.logo}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.webp';

  const formattedStartDate = moment(startDate, "YYYY-MM-DD");
  const formattedEndDate = moment(endDate, "YYYY-MM-DD");
  const eventLenght = returnLenghtBetweenDates(formattedStartDate, formattedEndDate);

  const id = props.data.id;
  const eventDetailsUrl = `/wydarzenie/${id}/${eventHash}`
  const organizerPageUrl = `/organizator/${organizer.data.id}/${eventHash}`

  // return (
  //   <Card sx={{  maxWidth: 345, m: '10px'}}>
  //     <CardHeader
  //       avatar={
  //         <Avatar
  //           component = {Link}
  //           to ={organizerPageUrl}
  //           alt="Organizer logo"
  //           src={organizerLogoReized} />
  //       }
  //       title={ name }
  //       subheader={`${ formattedStartDate.date() } ${ formattedStartDate.format('MMMM') } ${ formattedStartDate.format('YYYY') } ∙  ${eventLenght}`}
  //       sx={{ height: '55px'}}
  //     />
  //     <CardMedia
  //       component="img"
  //       height="190"
  //       width="345"
  //       loading="lazy"
  //       image={coverImageResized}
  //       alt={ name }
  //     />
  //     <CardContent >
  //       <Typography variant="body2" color="text.secondary">
  //       Czy jest coś lepszego niż przetestowanie nowego GS-a w egzotycznym królestwie Maroka? Z organizacją ADVPoland będzie bezpiecznie, komfortowo i klimatycznie. 
  //       </Typography>
  //     </CardContent>
  //     <CardActions>
  //       <IconButton aria-label="add to favorites">
  //         <FavoriteBorderIcon  color="primary" />
  //       </IconButton>
  //       <Button 
  //         component = {Link}
  //         to ={ eventDetailsUrl }
  //         size="small" color="primary">
  //           Więcej informacji
  //       </Button>
  //     </CardActions>
  //   </Card>
  // );

  return (
    <Card sx={{  maxWidth: 318, m: '10px', borderRadius: '8px'}} variant="outlined">
      <CardMedia
        component="img"
        height="180"
        width="318"
        loading="lazy"
        image={coverImageResized}
        alt={ name }
      />

      
      <CardContent >

      <Grid container spacing={1} sx={{mb: '5px'}}>
          <Grid item xs='auto'>
            <VerifiedIcon color="primary" sx={{width: '28px'}}/>
          </Grid>
          <Grid item xs='auto'>
            <Avatar
              component = {Link}
              to ={organizerPageUrl}
              alt="Organizer logo"
              src={organizerLogoReized} 
              sx={{ width: 20, height: 20 }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography gutterBottom variant="subtitle1" component="div" sx={{fontSize: '12px'}}>
              {organizer?.data?.attributes?.organizerName}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{mb: '10px', fontSize: '17px'}}>
          {name}
        </Typography>

        <Grid container spacing={1} alignItems="center">
          <Grid item xs='auto'>
            <EventIcon  color="primary" sx={{width: '18px'}}/>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" sx={{fontSize: '14px'}}>
              {`${ formattedStartDate.date() } ${ formattedStartDate.format('MMMM') } ${ formattedStartDate.format('YYYY') } ∙  ${eventLenght}`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{mb: '5px'}} alignItems="center">
          <Grid item xs='auto'>
            <DirectionsIcon color="primary" sx={{width: '18px'}} />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" sx={{fontSize: '14px'}}>
              Maroko - Andaluzja - Egipt
            </Typography>
          </Grid>
        </Grid>
        
        {/* <Typography variant="body2" color="text.secondary" sx={{fontSize: '14px'}}>
          Czy jest coś lepszego niż przetestowanie nowego GS-a w egzotycznym królestwie Maroka? Z organizacją ADVPoland będzie bezpiecznie, komfortowo i klimatycznie. 
        </Typography> */}
      </CardContent>
     
      <CardActions sx={{p: '15px', pt: '0'}}>
        <Button 
          component = {Link}
          to ={ eventDetailsUrl }
          size="small" color="primary"
          variant='outlined'
          sx={{width: '100%', mr: '10px'}}  
        >
            Więcej informacji
        </Button>
        <IconButton aria-label="add to favorites">
          <FavoriteBorderIcon  color="primary" />
        </IconButton>
      </CardActions>
    </Card>
  );
}