import './styles.scss';
import 'pages/styles.scss';
import AddEventTemplate from "./template";
import React from 'react';

class AddEvent extends React.Component {
    render() {
        return <AddEventTemplate/>
    }
}

export default AddEvent;