import React, { useState, useEffect } from 'react';
import './eventList.scss';
import EventCard from './eventCard';
import LoaderCovering from "../loader/covering/loader";
import { useParams } from "react-router-dom";
import { fetchTagByHash, fetchEventsFiltered } from 'components/data/database';
import Filters from "components/filters/";
import { Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import moment from "moment";

function NoData() {
    return (
        <div className="no-data">
            Brak wyników spełniających kryteria. <br></br>
            Jeśli jesteś organizatorem i chcesz aby twój event znalazł się w tym miejscu to daj znać.
        </div>
    )
}


export default function EventList () {
    let { tags:tagsParam, category: categoryParam, country: countryParam, location: locationParam, page: pageParam } = useParams();
    
    const [tag, setTag] = useState();
    const [noData, setNoData] = useState(false);
    const [events, setEvents] = useState();
    const [isLoading, setIsLoading] = useState();
    const [pagination, setPagination] = useState();
    const [selectedPage, setSelectedPage] = useState((pageParam ? Number(pageParam) : 1));

    const today = moment().format("YYYY-MM-DD");
    
    let history = useHistory();

    const handlePagginationChange = (event, page)=> {
        history.push(`/wydarzenia/${categoryParam}/${countryParam}/${locationParam}/${tagsParam}/${page}`)
        setSelectedPage(page);
    }

    const theme = createTheme({
        palette: {
          primary: {
            main: '#d9534f',
          },
          secondary: {
            main: '#11cb5f',
          },
        },
      });

      
    useEffect(() => {
        setIsLoading(true)

        async function fetchData() {
            await fetchTagByHash(tagsParam).then(tags => {
                setTag(tags?.data[0]?.attributes);
            });

            await fetchEventsFiltered({tagHash: tagsParam, categoryHash: categoryParam, countyrHash: countryParam, locationHash: locationParam, page: pageParam, today}).then(events => {
                setEvents(events.data);
                setPagination(events.meta.pagination);
                setIsLoading(false);
                setNoData(events.data.length < 1);
            });
        }
       
        fetchData();
    }, [tagsParam, categoryParam, pageParam, countryParam, locationParam, today])

    
    return (
        <>
            <ThemeProvider theme={theme}>
                <Filters />
                <div className='events-list'>
                    { isLoading && <LoaderCovering/>}
                    { tag ? 
                        <div className='tagName'>
                            <div className="tagNameContainer">
                                <h1>{tag?.tagName} {}</h1>
                            </div>
                            <div className="tagNameContainer__description">
                                <p>{tag?.tagDescription}</p>
                            </div>
                        </div>
                        : null
                    }
                    { noData && <NoData/>}
                    { ((events?.length > 0)) ? events.map((event, index) => {
                        return (
                            <EventCard 
                                key={index+'i'}
                                data={event}
                            />
                        )
                    }) :  null }

                    
                    <div className='paggination'>
                        { (pagination?.pageCount > 1) ? 
                            
                            <Pagination count={pagination?.pageCount} page={selectedPage} onChange={handlePagginationChange} />
                         :  null }
                    </div>
                </div>
            </ThemeProvider>
        </>
    )
}

