import 'pages/styles.scss';
import 'pages/notFound/styles.scss';
import React from 'react';

class NotFound extends React.Component {
    render() {
        return <div className="pageContainer not-found">
                <div className="not-found__404">Błąd 404</div>
                <h3>Strona której szukasz nie została znaleziona</h3>
                <a className="not-found__button" href="/">Przeglądaj wszystkie wydarzenia</a>
                <a className="not-found__button" href="/dodaj-wydarzenie">Dodaj wydarzenie</a>
            </div>
    }
}

export default NotFound;