import React from 'react';

async function sendEmail({link, eventName, startDate, startHour, endDate, endHour, country, province, city, moreInfo}) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `sdf3gfd=324dfsdldf_dsf33423&fgnza22df67=${link}&dnbcsdf323=${moreInfo}&asdffe456=${eventName}&sdfnkg986=${startDate}`,
    };

    return await fetch('https://mootoz.pl/api/sflasehssdfEdf.php', requestOptions)
        .then(response => response)
        .catch(error => error);
}

class AddEventTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventName: '',
            startDate: '',
            startHour: '',
            endDate: '',
            endHour: '',
            eventLink: '',
            country: '',
            city: '',
            province: undefined,
            address: '',
            contactPerson: '',
            additionalInformation: '',
            acceptTermAndConditions: false,
            emailSent: false,
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
      }
    
      async handleSubmit(event) {
        event.preventDefault();
        const link = this.state.eventLink;
        const moreInfo = this.state.additionalInformation;

        const eventName = this.state.eventName;

        const startDate = this.state.startDate;
        const startHour = this.state.startHour;

        const endDate = this.state.endDate;
        const endHour = this.state.endHour;

        const country = this.state.country;
        const province = this.state.province;
        const city = this.state.city;

        const response = await sendEmail({link, eventName, startDate, startHour, endDate, endHour, country, province, city, moreInfo});

        if (response.status === 200) {
            this.setState({emailSent: 'success'})
        } else {
            this.setState({emailSent: 'error'})
        }
      }

    render() {
        return <div className="pageContainer add-event">
            <h3>Dodaj wydarzenie</h3>
            <p>Dodawanie wydarzeń w wersji podstawowej jest całkowicie bezpłatne.</p>

            { this.state.emailSent 
            ? <div className={`add-event__response add-event__response--${this.state.emailSent}`}>
                {this.state.emailSent === 'success'
                    ? <span>Dziękujemy za dodanie wydarzenia,<br/> zostało ono przesłane do naszego Zespołu.</span>
                    : <span>Coś poszło nie tak, spróbuj ponownie, <br/>lub skontaktuj się z nami drogą mailową.</span>
                }
            </div>
            :
                <form onSubmit={this.handleSubmit} className="add-event__form" autoComplete="off">
                    <p>Jeśli znasz link do wydarzenia na Facebooku, na stronie organizatora lub masz link do plakatu, to wystaczy że wpiszessz go w polu poniżej.
                    Jednak będzie nam niezmienrie miło jeśli uzupełnisz pozostałe pola, ułatwi nam to pracę i przyśpieszy wprowadzanie wydarzeń.</p>

                    <div className="add-event__element">
                        <label className="add-event__label add-event__label--required"  htmlFor="">Link do wydarzenia lub plakatu: *</label>
                        <input required name="eventLink" placeholder="Np. https://facebook.com/events/123456" className="add-event__input" type="text" value={this.state.eventLink} onChange={this.handleChange} />
                    </div>

                    <div className="add-event__dates">
                        <h4>Dodatkowe informacje</h4>
                    </div>
                    <div className="add-event__element add-event__element--full">
                        <label className="add-event__label" htmlFor="">Nazwa wydarzenia:</label>
                        <input required name="eventName"  className="add-event__input" type="text" value={this.state.eventName} onChange={this.handleChange} />

                    </div>
                    <div className="add-event__dates">
                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Data rozpoczęcia:</label>
                            <input name="startDate" className="add-event__input" type="date" value={this.state.startDate} onChange={this.handleChange} />
                        </div>

                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Godzina rozpoczęcia:</label>
                            <input name="startHour" className="add-event__input" type="time" value={this.state.startHour} onChange={this.handleChange} />
                        </div>

                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Data zakończenia:</label>
                            <input name="endDate" className="add-event__input" type="date" value={this.state.endDate} onChange={this.handleChange} />
                        </div>

                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Godzina zakończenia:</label>
                            <input name="endHour" className="add-event__input" type="time" value={this.state.endHour} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="add-event__dates">
                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Kraj:</label>
                            <input name="country" placeholder="Kraj w którym odbywa się wydarzenie" className="add-event__input" type="text" value={this.state.country} onChange={this.handleChange} />
                        </div>

                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Województwo:</label>
                            <input name="province" placeholder="Województwo w którym odbywa się wydarzenie" className="add-event__input" type="text" value={this.state.province} onChange={this.handleChange} />
                        </div>

                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Miasto:</label>
                            <input name="city" placeholder="Miasto w którym odbywa się wydarzenie" className="add-event__input" type="text" value={this.state.city} onChange={this.handleChange} />
                        </div>

                        <div className="add-event__element">
                            <label className="add-event__label" htmlFor="">Dokładniejszy adres:</label>
                            <input name="address" placeholder="Np. ulica lub nazwa obiektu" className="add-event__input" type="text" value={this.state.address} onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="add-event__element add-event__element--full">
                        <label className="add-event__label" htmlFor="">Opis wydarzenia:</label>
                        <textarea rows={4} className="add-event__input" onChange={this.handleChange}>{this.state.additionalInformation}</textarea>
                    </div>
                    <div>
                        <input className="add-event__button"type="submit" value="Dodaj wydarzenie" />
                    </div>
                </form>
            }

            <div className="add-event__additional-info">Wydarzenie można również dodać wysyłając maila na adres: <a href="mailto:dodaj@mootoz.pl">dodaj@mootoz.pl</a></div>
            <div className="add-event__additional-info add-event__additional-info--smaller">
                Aby wydarzenie pojawiło się na Mootoz.pl, musi zostać zaakceptowane przez nasz Zespół. <br/><br/>
                Przed dodaniem wydarzenia staramy się skontaktować z jego organizatorem celem potwierdzenia uzyskanych w zgłoszeniu informacji. <br/><br/>
                Po publikacji wydarzenia celem modyfikacji podanych w nim informacji lub też jego usunięcia Organizator może skontaktować się z nami  wysyłając wiadomość na adres e-mail: kontakt@mootoz.pl
                w temacie wiadomości wpisując nazwę i miejsce wydarzenia.
            </div>

            <div className="add-event__required-text">* Pola wymagane</div>
        </div>
    }
}

export default AddEventTemplate;