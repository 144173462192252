import './styles.scss';

import { useState, useEffect } from 'react';
import { fetchTrips } from 'components/data/database';
import TripCard from 'components/tripCard';

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from "moment";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Drawer from '@mui/material/Drawer';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { plPL } from '@mui/x-date-pickers';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { pl } from 'react-date-range/dist/locale';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { TextField, MenuItem } from '@mui/material';

import Fab from '@mui/material/Fab';

import FormControlLabel from '@mui/material/FormControlLabel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function TripsCategoryPage(props) {
  const [trips, setTrips] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const fetchData = async (startDate, endDate) =>{
      await fetchTrips(startDate, endDate).then(trips => {
          setTrips(trips.data);
      });
  }

  useEffect(() => {
      fetchData();
  }, []);


  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

    const [tripsOpen, setTripsOpen] = useState(false);
    const [eventsOpen, setEventsOpen] = useState(false);
    const [trainingsOpen, setTrainingsOpen] = useState(true);

    const handleClick = (type) => {
      if (type === 'trips') {
          setTripsOpen(true);
          setEventsOpen(false);
          setTrainingsOpen(false);
      }

      if (type === 'events') {
          setTripsOpen(false);
          setEventsOpen(true);
          setTrainingsOpen(false);
      }

      if (type === 'trainings') {
          setTripsOpen(false);
          setEventsOpen(false);
          setTrainingsOpen(true);
      }
    };

  const resetDateFilters = () => {
      fetchData();
  }
  const changeDate = (item) => {
      setState([item.selection])
      const startDate = item.selection.startDate ? moment(item.selection.startDate).format("YYYY-MM-DD") : null;
      const endDate = item.selection.endDate ? moment(item.selection.endDate).format("YYYY-MM-DD") : null;
      
      fetchData(startDate, endDate);
  }
  const drawerWidth = 300;

  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
  };

  const filters = (
    <div className="trips-category-page__filters">
      <Typography variant="h5" component="h5" sx={{fontSize: '18px', mb: '15px', mt: '25px'}}>
      Kategorie
    </Typography>
      <List
        sx={{ width: '100%', mt: '10px', mb: '20px', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick('events')}>
          <ListItemText  primary="Wydarzenia" />
          {eventsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={eventsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          <FormControlLabel
    label="Wszystkie"
    sx={{ml: '15px', width: '100%'}}
    control={
      <Checkbox
        checked={true}
        indeterminate={false}
      />
    }
  />
    <FormControlLabel
      label="Rozpoczęcie sezonu"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={true}
        />
      }
    />

  <FormControlLabel
          label="Zloty"
          sx={{ml: '15px', width: '100%'}}
          control={
            <Checkbox
              checked={true}
            />
          }
        />

<FormControlLabel
          label="Targi"
          sx={{ml: '15px', width: '100%'}}
          control={
            <Checkbox
              checked={true}
            />
          }
        />

<FormControlLabel
          label="Bazary"
          sx={{ml: '15px', width: '100%'}}
          control={
            <Checkbox
              checked={true}
            />
          }
        />

    <FormControlLabel
      label="Motoserce"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={true}
        />
      }
    />
          </List>
        </Collapse>

        <ListItemButton onClick={() => handleClick('trainings')}>
          <ListItemText sx={{color: 'tomato'}}  primary="Szkolenia" />
          {trainingsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={trainingsOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{pl: 2}}>
              <ListItemButton dense={true}>
                  <ListItemText sx={{color: 'tomato'}} primary="Wszystkie" />
              </ListItemButton>
              <ListItemButton dense={true}>
                  <ListItemText primary="Drogowe" />
              </ListItemButton>
              <ListItemButton dense={true}>
                  <ListItemText primary="Torowe" />
              </ListItemButton>
          </List>
        </Collapse>

                
        <ListItemButton onClick={() => handleClick('trips')}>
            <ListItemText  sx={{ fontWeight: "bold" }} primary="Wyprawy" />
            {tripsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={tripsOpen} timeout="auto" unmountOnExit>
            <List component="div"  sx={{pl: 2}}>
                <ListItemButton dense={true}>
                    <ListItemText primary="Wszędzie" />
                </ListItemButton>
                <ListItemButton dense={true}>
                    <ListItemText primary="Polska" />
                </ListItemButton>
                <ListItemButton dense={true}>
                    <ListItemText primary="Hiszpania" />
                </ListItemButton>
                <ListItemButton dense={true}>
                    <ListItemText primary="Grecja" />
                </ListItemButton>
                <ListItemButton dense={true}>
                    <ListItemText primary="Islandia" />
                </ListItemButton>
            </List>
        </Collapse>
      </List>
{/*       
      <div className='trips-category-page__labels'>
        <span>Wyjazd od</span>
        <span>powrót do</span>
      </div>
        <DateRange
            editableDateInputs={false}
            onChange={changeDate}
            moveRangeOnFirstSelection={false}
            retainEndDateOnFirstSelection={true}
            ranges={state}
            locale={pl}
            months={1}
            showSelectionPreview={true}
            rangeColors={['#d9534f']}
            minDate={new Date()}
            direction='horizontal'
            startDatePlaceholder='dowolnie'
            endDatePlaceholder='dowolnie'
        />

          <Button sx={{mt: '20px', width: '100%'}} size='big' startIcon={<EventBusyIcon />} 
          onClick={() => {
              setState([{
                  startDate: null,
                  endDate: null,
                  key: 'selection'
              }])
              resetDateFilters();
          }}>
              Pokaż wszystkie
          </Button>

          

          <Button sx={{mt: '20px', width: '100%'}} size='big' startIcon={<SearchIcon />}>
              Szukaj
          </Button> */}

{/* <Typography variant="h5" component="h5" sx={{fontSize: '18px', mb: '15px'}}>
  Lokalizacja
</Typography>

<TextField
        id="country-select"
        select
        label="Państwo"
        defaultValue="wszedzie"
        size="small"
        sx={{ width: '100%', mr: '15px', mt: '15px' }}
    >
        <MenuItem key="wszedzie" value="wszedzie">
                Wszędzie
        </MenuItem>
        
        <MenuItem key="polska" value="polska">
            Polska
        </MenuItem>

</TextField> 

<TextField
    id="province=select"
    select
    label="Województwo"
    defaultValue="wszedzie"
    size="small"
    sx={{ width: '100%', mr: '15px', mt: '15px' }}
>
    <MenuItem key="wszedzie" value="wszedzie">
            Wszędzie
    </MenuItem>
    <MenuItem key="slaskie" value="slaskie">
            Śląskie
        </MenuItem>
</TextField>  */}
{/* 
<Typography variant="h5" component="h5" sx={{fontSize: '18px', mb: '15px', mt: '25px'}}>
  Kategorie
</Typography>

  <FormControlLabel
    label="Wydarzenia"
    control={
      <Checkbox
        checked={false}
        indeterminate={true}
      />
    }
  />
    <FormControlLabel
      label="Rozpoczęcie sezonu"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={true}
        />
      }
    />

  <FormControlLabel
          label="Zloty"
          sx={{ml: '15px', width: '100%'}}
          control={
            <Checkbox
              checked={true}
            />
          }
        />

<FormControlLabel
          label="Targi"
          sx={{ml: '15px', width: '100%'}}
          control={
            <Checkbox
              checked={false}
            />
          }
        />

<FormControlLabel
          label="Bazary"
          sx={{ml: '15px', width: '100%'}}
          control={
            <Checkbox
              checked={false}
            />
          }
        />

    <FormControlLabel
      label="Motoserce"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={false}
        />
      }
    />
  <FormControlLabel
    label="Szkolenia"
    sx={{mt: '15px', width: '100%'}}
    control={
      <Checkbox
        checked={false}
        indeterminate={true}
      />
    }
  />
    <FormControlLabel
      label="Torowe"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={false}
        />
      }
    />
    <FormControlLabel
      label="Drogowe"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={false}
        />
      }
    />

<FormControlLabel
    label="Wyprawy"
    sx={{mt: '15px', width: '100%'}}
    control={
      <Checkbox
        checked={false}
        indeterminate={true}
      />
    }
  />
    <FormControlLabel
      label="Track Days"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={false}
        />
      }
    />
    <FormControlLabel
      label="Szkolenia"
      sx={{ml: '15px', width: '100%'}}
      control={
        <Checkbox
          checked={false}
        />
      }
    /> */}

<FormControlLabel control={<Switch />} label="Tylko kilkudniowe" />


<LocalizationProvider dateAdapter={AdapterMoment}>
   

<Typography variant="h5" component="h5" sx={{fontSize: '18px', mb: '15px', mt: '25px'}}>
  Termin
</Typography>

<Button variant="outlined">W ten weekend</Button>
<Button variant="outlined">w tym tygodniu</Button>
<Button variant="outlined">W tym miesiącu</Button>
<DatePicker
          label="Data od"
          inputFormat="MM/DD/YYYY"
          value={'11 02 2021'}
        //   onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />

<DatePicker
          label="Data do"
          inputFormat="MM/DD/YYYY"
          value={'11 02 2021'}
        //   onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
          />
    </LocalizationProvider>
      </div>
    )

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: { xs: 'flex', sm: 'none' },
      };

  return (
    <div className="trips-category-page">
      <Fab color="primary" sx={fabStyle} onClick={handleDrawerToggle}>
        <SearchIcon />
      </Fab>
          
      <div className='filters-on-desktop'>
          { filters }
      </div>
          
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, pl: '25px' },
        }}
      >
        {filters}
      </Drawer>
      <div className='trips-category-page__body'>  
        {((trips?.length > 0)) ? trips.map((trip, i) => {
          return (
            <TripCard 
              key={i}
              data={trip}
            />
          )
        }) :  null }
      </div>  
    </div>
  )
}


// <div>
// <TextField
// id="country-select"
// select
// label="Państwo"
// defaultValue="wszedzie"
// size="small"
// sx={{ width: '100%', mr: '15px', mt: '15px' }}
// >
// <MenuItem key="wszedzie" value="wszedzie">
// Wszędzie
// </MenuItem>

// <MenuItem key="polska" value="polska">
// Polska
// </MenuItem>

// </TextField> 

// <TextField
// id="province=select"
// select
// label="Województwo"
// defaultValue="wszedzie"
// size="small"
// sx={{ width: '100%', mr: '15px', mt: '15px' }}
// >
// <MenuItem key="wszedzie" value="wszedzie">
//   Wszędzie
// </MenuItem>
// <MenuItem key="slaskie" value="slaskie">
//   Śląskie
// </MenuItem>
// </TextField> 
// </div>