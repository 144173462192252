import 'pages/styles.scss';
import './styles.scss';
import React from 'react';
// import { fetchNews } from 'components/data/database';
import moment from "moment";
import bookmarkIcon from "assets/img/bookmark_icon.svg"

function Loader() {
    return (
        <div className="news__element loading">
            <div className="news__header animation-pulse">
                <div className="news__date"></div>
                <div className="news__title"></div>
            </div>
            
            <div className="news__image"></div>
            <div className="news__body animation-pulse">
                <div className="news__content-line"></div>
                <div className="news__content-line"></div>
                <div className="news__content-line"></div>
            </div>
            <div className="news__footer">
                <div className="news__logo"></div>
                <div className="news__company"></div>
            </div>
        </div>
    )
}

class NewsPage extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            news: [],
            newsOriginal: [],
            isLoading: true,
            forLaterActive: false,
        }
    }

    showLoader(){
        this.setState({
            isLoading: true,
        });
    }

    hideLoader(){
        setTimeout(()=> {
            this.setState({
                isLoading: false,
            });
        }, 300)
    }

    sortByDate(dateA, dateB) {
        const firstDateMoment = moment(dateA.publicationDate);
        const secondDateMoment = moment(dateB.publicationDate);
    
        return firstDateMoment.diff(secondDateMoment) <= 0 ? 1 : -1;
    }

    onlyToday = () => {
        const news = this.state.news.filter(element => {
            const publicationDate =  moment(element.publicationDate);
            const now = moment();
            return moment(publicationDate).isSameOrAfter(now, 'day');
        })

        this.setState({
            news,
        });
        this.scrollToTop();
    }

    showAll = () => {
        this.showLoader();
        this.setState({
            news: this.state.newsOriginal,
        });
        this.scrollToTop();
        this.hideLoader();
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    reload() {
        window.location.reload();
    }

    // async componentDidMount() {
    //     await fetchNews.then(news => {
    //         news.sort(this.sortByDate);

    //         this.setState({
    //             news,
    //             newsOriginal: news,
    //             isLoading: false,
    //         });
    //     });
    // }

    filterByCompany = () => {
        this.scrollToTop();
        this.showLoader();

        const filteredNews = this.state.newsOriginal.filter(element => element.company === "swiatmotocykli.pl");

        this.setState({
            news: filteredNews,
        });

        this.hideLoader();
    }

    toggleForLater(e, guid) {
        if (localStorage.getItem(guid)) {
            this.showLoader();
            localStorage.removeItem(guid);
            e.target.classList.remove("for-later");
            this.state.forLaterActive && this.filterForLater();
            this.hideLoader();
        } else {
            localStorage.setItem(guid, true);
            e.target.classList.add("for-later");
        }
    }

    checkForLater(guid) {
        return localStorage.getItem(guid);
    }

    filterForLater = () => {
        this.scrollToTop();
        this.showLoader();

        const filteredNews = this.state.newsOriginal.filter(element => this.checkForLater(element.guid));

        this.setState({
            news: filteredNews,
            forLaterActive: true,
        });

        this.hideLoader();
    }

    render() {
        return (
            <div className="news__page">
                {this.state.isLoading ? <div className="news__page"><Loader /><Loader /><Loader /><Loader /><Loader /><Loader /></div> :
                    this.state.news.map(element => {
                        const publicationDate = moment(element.publicationDate);
                        return (
                            <div className="news__element" key={element.id}>
                                <a className="news__link" href={element.link} target="_blank" rel="noreferrer">
                                    <div className="news__footer">
                                        <img className="news__logo" src="https://motogen.pl/wp-content/uploads/2019/06/cropped-favicon-motogen3-32x32.png" alt=""/>
                                        <div>
                                            <div className="news__date">{(publicationDate.fromNow())} | {publicationDate.format("DD-MM-YYYY HH:MM")}</div>
                                            <div className="news__company">{element.company}</div>
                                        </div>
                                    </div>
                                    <div className="news__header">
                                        {/* <div className="news__date">{(publicationDate.fromNow())} | {publicationDate.format("DD-MM-YYYY HH:MM")}</div> */}
                                        <h3 className="news__title">{element.title}</h3>
                                    </div>
                                    
                                    <div className="news__image"><img width="333px"src={`https://mootoz.pl/${element.coverImage}`} alt=""/></div>
                                    <div className="news__body">
                                        <div  className="news__description" dangerouslySetInnerHTML={{__html: element.description.replace(/(<([^>]+)>)/gi, "")}} />
                                    </div>
                                </a>
                                <div className="news__actions">
                                    <div>
                                        <svg fill="none" height="24" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><circle cx="18" cy="5" r="3"/><circle cx="6" cy="12" r="3"/><circle cx="18" cy="19" r="3"/><line x1="8.59" x2="15.42" y1="13.51" y2="17.49"/><line x1="15.41" x2="8.59" y1="6.51" y2="10.49"/></svg>
                                    </div>
                                    <div onClick={this.reload}>
                                    <svg fill="none" height="24" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"/><line x1="4.93" x2="19.07" y1="4.93" y2="19.07"/></svg>
                                    </div>
                                    <div onClick={(e) => this.toggleForLater(e, element.guid)}>
                                        <svg className={this.checkForLater(element.guid) && "news__bookmark--selected"} fill="none" height="24" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"/></svg>
                                    </div>
                                </div>
                            </div>
                        )
                    }) 
                }
                
                
                <div className="news__menu-bottom">
                    <div onClick={this.reload} className="news__menu-item">
                        R
                    </div>
                    <div onClick={this.showAll} className="news__menu-item">
                        Wszystkie
                    </div>
                    <div onClick={this.filterForLater} className="news__menu-item">
                        Na potem
                    </div>
                    <div onClick={this.filterByCompany} className="news__menu-item">
                        Filtry
                    </div>
                    <div className="news__menu-item">
                        Ukryte
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsPage;