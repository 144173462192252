import 'pages/styles.scss';
import './styles.scss';
import React, { useState, useEffect } from 'react';
import { fetchEvent } from 'components/data/database';
import Seo from 'components/seo';
import {
    useParams,
    useHistory
  } from "react-router-dom";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Chip, Button } from '@mui/material';
import moment from "moment";
import 'moment/locale/pl';
import ShareIcon from '@mui/icons-material/Share';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { CardActionArea, CardActions, Typography, AvatarGroup, Avatar, Tooltip } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import Fab from '@mui/material/Fab';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import 'add-to-calendar-button';

moment.locale('pl');

export default function EventPage(props) {
    const [event, setEvent] = useState([]);
    const [isPosterVisible, setPosterVisible] = useState(false);
    // const imgUrlResized = event.img ? `https://res.cloudinary.com/dym1ldlty/image/upload/c_scale,h_315/v1673219437/test/${event.img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    const imgUrlResized = event.img ? `https://res.cloudinary.com/dym1ldlty/image/upload/b_auto:predominant_gradient:2:diagonal_desc,c_pad,h_280,w_400/v1676127178/test/${event.img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
    
    
    const imgUrlFull = event.img ? `https://res.cloudinary.com/dym1ldlty/image/upload/v1673219437/test/${event.img}.webp` : 'https://res.cloudinary.com/dym1ldlty/image/upload/v1673978771/test/imagePlaceholder_olkebp.webp';
   
    let { id } = useParams();
    let history = useHistory();

    const calendarConfig = {
        buttonStyle:"round",
        label:"Dodaj do kalendarza",
        name:"Title 1",
        options:"'Apple','Google'",
        location:"World Wide Web",
        startDate:"2023-09-11",
        endDate:"2023-09-11",
        startTime:"10:15",
        endTime:"23:30",
        timeZone:"America/Los_Angeles"
    }

    useEffect(() => {
        const fetchData = async () =>{
            await fetchEvent(id).then(event => {
                setEvent(event.data.attributes);
                // document.title = `${event.data.attributes.eventName} - Mootoz.pl wszystkie wydarzenia mtororyzacyjne w jednym miejscu`;
            });
        }
        fetchData();
        
    }, []);

    const goBack = () => {
        history.goBack();
    }

    const formattedStartDate = moment(event?.startDate, "YYYY-MM-DD");
    const formattedEndDate = moment(event?.endDate, "YYYY-MM-DD");
    const formattedStartHour = event?.startHour ? moment(event?.startHour, "H:m:s")?.format(' - H:mm') : '';
    const formattedEndHour = event?.endHour ? moment(event?.endHour, "H:m:s")?.format(' - H:mm') : '';
    
    return (
        <div className="event-page">
            {isPosterVisible && <Lightbox allowRotate={false} image={imgUrlFull} title="Plakat" onClose={()=>{setPosterVisible(false)}} />}

            <Seo 
                title={event.eventName ? event.eventName : 'Mootoz - Wydarzenia motoryzacyjne'}
                // description="test desctr"
                // url="url"
                type="events.event"
                image={imgUrlResized}
            />
            {/* <Helmet>

                <title>{'title'}</title>
                <meta name='description' content={'description'} />

                <meta property="og:type" content={'type'} />
                <meta property="og:title" content={'title'} />
                <meta property="og:description" content={'description'} />

                <meta name="twitter:creator" content={'name'} />
                <meta name="twitter:card" content={'type'} />
                <meta name="twitter:title" content={'title'} />
                <meta name="twitter:description" content={'description'} />

            </Helmet> */}
     
            {/* <Card sx={{ display: 'flex', mb: 3, width: '100%' }}>
                <CardMedia
                    component="img"
                    sx={{ height: '300px', maxWidth: 600 }}
                    image={imgUrlResized}
                    alt="Live from space album cover"
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', width: 900 }}>
                    <CardContent sx={{ flex: '1 1 auto' }}>
                        <Typography component="div" variant="h4">
                            {event.eventName}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                        {formattedStartDate.format('dddd')}, <b>{formattedStartDate.format('DD MMMM YYYY')} - 10:00</b>
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
 
                    </Box>
                </Box>
               
            </Card> */}
            <div>
            <Button className='back-button' onClick={goBack} size="small" variant="text" sx={{mb: '15px'}} startIcon={<ArrowBackIosNewIcon />}>
                Wstecz
            </Button>
            </div>
            <div className='header'>
                <div className='header__image'>
                    <img src={imgUrlResized} alt="Zdjęcie wydarzenia" onClick={()=>{setPosterVisible(true)}} />
                    <Button size="small" variant="contained" color='primary' aria-label="Powiększ plakat" onClick={()=>{setPosterVisible(true)}}>
                        <ZoomInIcon /> Powiększ plakat
                    </Button>
                </div>
                <div className='header__content'>
                    <h1>{event.eventName}</h1>

                    <div className='event-page__date'>
                        <EventAvailableOutlinedIcon sx={{ mr: '10px', color: '#d9534f' }}/>
                        <div>
                            <div>{formattedStartDate.format('dddd')}, <b>{formattedStartDate.format('DD MMMM YYYY')} {formattedStartHour}</b></div>
                            <div>do {formattedEndDate.format('DD MMMM YYYY')} {formattedEndHour}</div>
                        </div>
                    </div>

                    <div className='event-page__localization'>
                        <MapOutlinedIcon sx={{ mr: '10px', color: '#d9534f' }}/>
                        <div>
                            <div><b>{event?.eventCity}</b>, {event?.province?.data?.attributes?.provinceName} - {event?.country?.data?.attributes?.countryName}</div>
                            <div>{event?.eventAddress}</div>  
                        </div>
                    </div>

                    <div>
                        {event.categories?.data.map((category) => {
                            return (
                                <Chip key={category.id} label={category.attributes.categoryName}   sx={{ mt: '15px', mr: '15px' }} /> 
                            )
                        })}
                        {event.tags?.data.map((tag) => {
                            return (
                                <Chip key={tag.id} label={tag.attributes.tagName} variant="outlined"  sx={{ mt: '15px', mr: '15px' }} /> 
                            )
                        })}
                    </div>

                    {/* <add-to-calendar-button {...calendarConfig}></add-to-calendar-button> */}
                </div>
            </div>

            <div className='event-page__details'>
                <Typography variant="subtitle1">
                   <b>Szczegóły wydarzenia - informacje organizatora</b>
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: event.eventDescription }} />
            </div>

            <div className='event-page__details'>
                <div className='event-page__organizers'>
                {event.organizers?.data.length > 0 ?
                        <>
                            <Typography variant="subtitle1">
                                <b>Organizatorzy:</b>
                            </Typography>
                            {event.organizers?.data.map((organizer) => 
                                <Chip 
                                    key={organizer.id} 
                                    label={organizer.attributes.organizerName} 
                                    variant="outlined" 
                                    sx={{ mt: '15px', mr: '15px' }} 
                                    component="a"
                                    href={organizer.attributes.facebookUrl} 
                                    clickable
                                    target="_blank"
                                    icon={<FacebookOutlinedIcon />} 
                                /> 
                            )}
                        </>
                    : null }
                    
                    {/* <Typography variant="body2" sx={{ mt: '15px', mb: '5px' }}>
                        Wydarzenie dodane przez:
                     </Typography> */}
{/* 
                    <Tooltip title="Dodane przez Admnistatora Mootoz" placement="top">
                        <AvatarGroup max={5} sx={{ justifyContent: 'left', mb: 2}}>
                            <Avatar alt="Mootoz" src="https://res.cloudinary.com/dym1ldlty/image/upload/v1674121408/test/mootozLogo60_rhbcvk.jpg" />
                        </AvatarGroup>
                    </Tooltip> */}
{/* 
                    <Typography variant="body2" sx={{ mb: '5px' }}>
                        Wydarzenie dodane przez: <b>Moto Gymkhana Poznań</b>
                    </Typography> */}
                    
                    {/* <Typography variant="body2" sx={{ mb: '5px' }}>
                        Kontakt do organizatora: gymkhana.poznan@gmail.com
                    </Typography> */}
                   
                </div>
                <div className='event-page__buttons'>
                    {/* <Button startIcon={<ShareIcon />}>
                        Udostepnij wydrzenie
                    </Button> */}
                    <Typography variant="subtitle1">
                        <b>Wydarzenie na Facebooku:</b>
                    </Typography>
                    
                    {event.facebookEventUrl ?
                        <Button href={event.facebookEventUrl} target="_blank" startIcon={<FacebookOutlinedIcon />}>
                                Zobacz wydrzenie na Facebooku
                        </Button>
                    : <div>Nie mamy odnośnika do wydarzenia na facebooku, jeśli go znasz podeślij, uzupełnimy</div>}
                </div>
                
                {/* Add back button on the left on the image
                Free or paid
                Contact to the organiser

                Udostepnij */}
            </div>
                    
            {/* {event.facebookEventUrl ?
                <div>
                    <a href={event.facebookEventUrl}>Przejdź do wydarzenia na Facebooku</a> 
                </div>
            : null} */}
            

            {/* <div>
                <p>Aktualności</p>
                <div>
                    Update date:
                    01 Here will be updates stream that users will be notified with 
                </div>
                <div>
                    Update date:
                    02 Here will be updates stream that users will be notified with 
                </div>
            </div> */}

        </div>
    )
}
