import React from "react";
import './menuTop.scss';
import { Link } from 'react-router-dom';

import facebookLogo from "../../assets/img/facebook_round_logo.svg";
import mootozLogo from "../../assets/img/mootoz_logo_new.svg"

class MenuTop extends React.Component {  
    constructor(props){
        super(props);
        this.state= {isMenuOpened: false}
    }

    closeMenu = () => {
        this.setState({isMenuOpened: false});
    }

    toggleMainMenu = () => {
        this.closeMenu();
        this.setState({isMenuOpened: !this.state.isMenuOpened});
    }


    render(){
        return (
            <div className={this.state.isMenuOpened ? "menuTop mobile" : "menuTop"}>
                <div className="menuTop__wrapper">
                    <div className="menuTop__logo">
                        <a href="/" onClick={()=>this.closeMenu()}>
                            <img src={mootozLogo} alt="Mootoz logo"/>
                        </a>
                    </div>
                    <div className="menuTop__decoration"></div> 
                    <div className="menuTop__burger-menu" onClick={()=>this.toggleMainMenu()}>
                        <div className="menu-icon">
                            <span className="navicon"></span>
                        </div>
                    </div>
                    <div className="menuTop__mobile">
                        <div className="menuTop__header"><h1>Kalendarz <span className="menuTop__highlight">MOTO</span> wydarzeń</h1></div>
                        <Link className="menuTop__addEvent" onClick={()=>this.closeMenu()} to="/dodaj-wydarzenie">Dodaj wydarzenie</Link>
                        <Link className="menuTop__item" onClick={()=>this.closeMenu()} to="/kontakt">Kontakt</Link>
                        <div className="menuTop__fb">
                            <a href="https://www.facebook.com/MOOTOZPolska/" onClick={()=>this.closeMenu()} target="_blank" rel="noreferrer">
                                <img title="Mootoz na Facebooku" src={facebookLogo} alt="Mootoz na Facebooku"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuTop;