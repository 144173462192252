import 'components/footer/styles.scss';
// import mootozLogo from "assets/img/mootoz.svg"
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer__text">Mootoz to kalendarz wydarzeń motoryzacyjnych, przewodnik po zlotach oraz imprezach motocyklowych i samochodowych, rajdach, pokazach stuntu i wielu innych wydarzeniach dla miłośników motoryzacji.</div>
            <div className="footer__menu">
                <Link className="footer__link" to="/polityka-plikow-cookies">Polityka plików cookies</Link>
                <Link className="footer__link" to="/kontakt">Kontakt</Link>
                <Link className="footer__link" to="/dodaj-wydarzenie">Dodaj wydarzenie</Link>
                <Link className="footer__link" to="/">Wydarzenia</Link>
            </div>
            {/* <div className="footer__logo"><img src={mootozLogo} alt="Mootoz logo"/></div> */}
        </div>
    )
}