import React from 'react';
import {Helmet} from "react-helmet"

export default function SEO({title, description, type, image, url}) {
    return (
        <Helmet>
            <title>{`${title} - Mootoz.pl wszystkie wydarzenia mtororyzacyjne w jednym miejscu`}</title>
            {description && <meta name='description' content={description} /> }
            {description &&  <meta property="og:description" content={description} /> }
           
            <meta property="og:title" content={title} />
            <meta property="og:type" content={type} />
            {/* <meta property="og:url" content={url} /> */}
            <meta property="og:image" content={image} />
            
            {/* <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} /> */}
        </Helmet>
    )
}
