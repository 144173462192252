import React, { useState, useEffect } from 'react';
import './styles.scss';
import EventCard from 'components/eventsList/eventCard';
import LoaderCovering from "components/loader/covering/loader";
import { useParams } from "react-router-dom";
import { fetchTagByHash, fetchEventsFiltered } from 'components/data/database';
import Filters from "components/filters/";
import { Pagination } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import moment from "moment";
import Chip from '@mui/material/Chip';
import Seo from 'components/seo';

function NoData() {
    return (
        <div className="no-data">
            Brak wyników spełniających kryteria. <br></br>
            Jeśli jesteś organizatorem i chcesz aby twój event znalazł się w tym miejscu to daj znać.
        </div>
    )
}


export default function EventList () {
    let { tags:tagsParam, category: categoryParam, country: countryParam, location: locationParam, page: pageParam } = useParams();
    
    const [noData, setNoData] = useState(false);
    const [events, setEvents] = useState();
    const [isLoading, setIsLoading] = useState();
    const [pagination, setPagination] = useState();
    const [selectedPage, setSelectedPage] = useState((pageParam ? Number(pageParam) : 1));

    const today = moment().format("YYYY-MM-DD");
    
    let history = useHistory();

    const handlePagginationChange = (event, page)=> {
        const location = locationParam ? locationParam : 'wszedzie';
        history.push(`/motoserce/${location}/${page}`)
        setSelectedPage(page);
    }

    const theme = createTheme({
        palette: {
          primary: {
            main: '#d9534f',
          },
          secondary: {
            main: '#11cb5f',
          },
        },
      });

      
    useEffect(() => {
        setIsLoading(true)

        async function fetchData() {
            await fetchEventsFiltered({tagHash: 'motoserce', categoryHash: categoryParam, countyrHash: countryParam, locationHash: locationParam, page: pageParam, today}).then(events => {
                setEvents(events.data);
                setPagination(events.meta.pagination);
                setIsLoading(false);
                setNoData(events.data.length < 1);
            });
        }
       
        fetchData();
    }, [tagsParam, categoryParam, pageParam, countryParam, locationParam, today])

    const handleLocationChange = (location) => {
        history.push(`/motoserce/${location}`)
      };
    
    const isSelected = (location) => {
        if (location === locationParam) {
            return 'primary';
        }

        if (!locationParam && (location === 'wszedzie')) {
            return 'primary';
        }

        return 'default';
    }
    
    return (
        <>
            <Seo 
                title='Motoserce - krew darem życia'
                description="Lista aktualnych akcji. MOTOSERCE jest ideą wynikającą z potrzeby serca i z chęci działania."
                type="events.event"
                image='https://res.cloudinary.com/dym1ldlty/image/upload/v1681247230/motoserce/motoserceMootozOG_m5tqp3.png'
            />
            <ThemeProvider theme={theme}>
                <div className='motoserce events-list'>
                    { isLoading && <LoaderCovering className='loaderTop' />}
                    <div className='motoserceHeader tagName'>
                        <div className='motoserceHeader__logo'>
                            <img alt='Motoserce logo' src='https://res.cloudinary.com/dym1ldlty/image/upload/bo_0px_solid_rgb:ffffff,c_scale,r_100,w_200/v1681225238/motoserce/motoserce2023logo_inimvn.jpg'/>
                        </div>
                        <div className='motoserce__container'>
                            <div className="tagNameContainer">
                                <h1>Motoserce - krew darem życia</h1>
                            </div>
                            <div className="tagNameContainer__description">
                                <p className='bigger'>"MOTOSERCE jest ideą wynikającą z potrzeby serca i z chęci działania. Naszą akcją chcemy zwrócić uwagę na brak tego cennego daru jakim jest KREW w polskich szpitalach oraz pragniemy propagować idee krwiodawstwa”</p>
                                <p>Ogólnopolska Akcja Krwiodawstwa organizowana przez kluby zrzeszone w Kongresie Polskich Klubów Motocyklowych MOTOSERCE nie jest organizacją, ani stowarzyszeniem. Jest Ideą wynikającą z potrzeby serca i z chęci działania. Organizatorami są wyłącznie kluby zrzeszone przy KPKM, ewentualnie osoby, czy też kluby, które uzyskały zgodę Kongresu. Znak MOTOSERCA jest zastrzeżony na potrzeby Kongresu Polskich Klubów Motocyklowych.</p>
                                

                                <p>Ogólnopolska strona akcji: <a target='_blank' rel='noreferrer' href='https://motoserce.pl/'>https://motoserce.pl/</a> oraz profil na <a target='_blank' rel='noreferrer' href='https://www.facebook.com/profile.php?id=100064707099483'>Facebooku</a></p>
                                <p>Akcja oragnizowana jest w całej Polsce a nawet poza jej granicami. Poniżej znajdą Państwo linki do wydarzeń wraz ze szczegółami poszczególnych akcji.</p>
                            </div>
                        </div>
                    </div>

                    <div className='motoserceSubHeader'>
                        <h2>Aktualna lista wydarzeń</h2>
                    </div>
                    <div className='motoserceRegion'>
                        <Chip label="Wszędzie" variant="outlined" color={isSelected('wszedzie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('wszedzie')} />
                        <Chip label="Dolnośląskie" variant="outlined" color={isSelected('dolnoslaskie')} sx={{mr: '10px', mb: '10px'}}  onClick={()=> handleLocationChange('dolnoslaskie')} />
                        <Chip label="Kujawsko-pomorskie" variant="outlined" color={isSelected('kujawsko-pomorskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('kujawsko-pomorskie')} />
                        <Chip label="Lubelskie" variant="outlined" color={isSelected('lubelskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('lubelskie')} />
                        <Chip label="Lubuskie" variant="outlined" color={isSelected('lubuskie')} sx={{mr: '10px', mb: '10px'}}  onClick={()=> handleLocationChange('lubuskie')} />
                        <Chip label="Łódzkie" variant="outlined" color={isSelected('lodzkie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('lodzkie')} />
                        <Chip label="Małopolskie" variant="outlined" color={isSelected('malopolskie')} sx={{mr: '10px', mb: '10px'}}  onClick={()=> handleLocationChange('malopolskie')} />
                        <Chip label="Mazowieckie" variant="outlined" color={isSelected('mazowieckie')} sx={{mr: '10px', mb: '10px'}}  onClick={()=> handleLocationChange('mazowieckie')} />
                        <Chip label="Opolskie" variant="outlined" color={isSelected('opolskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('opolskie')} />
                        <Chip label="Podkarpackie" variant="outlined" color={isSelected('podkarpackie')} sx={{mr: '10px', mb: '10px'}}  onClick={()=> handleLocationChange('podkarpackie')} />
                        <Chip label="Podlaskie" variant="outlined" color={isSelected('podlaskie')} sx={{mr: '10px', mb: '10px'}}  onClick={()=> handleLocationChange('podlaskie')} />
                        <Chip label="Pomorskie" variant="outlined" color={isSelected('pomorskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('pomorskie')} />
                        <Chip label="Śląskie" variant="outlined" color={isSelected('slaskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('slaskie')} />
                        <Chip label="Świętokrzyskie" variant="outlined" color={isSelected('swietokrzyskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('swietokrzyskie')} />
                        <Chip label="Warmińsko-mazurskie" variant="outlined" color={isSelected('warminsko-mazurskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('warminsko-mazurskie')} />
                        <Chip label="Wielkopolskie" variant="outlined" color={isSelected('wielkopolskie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('wielkopolskie')} />
                        <Chip label="Zachodniopomorskie" variant="outlined" color={isSelected('zachodniopomorksie')} sx={{mr: '10px', mb: '10px'}} onClick={()=> handleLocationChange('zachodniopomorksie')} />
                    </div>
                    { noData && <NoData/>}
                    { ((events?.length > 0)) ? events.map((event, index) => {
                        return (
                            <EventCard 
                                key={index+'i'}
                                data={event}
                            />
                        )
                    }) :  null }
                    
                    <div className='paggination'>
                        { (pagination?.pageCount > 1) ? 
                            <Pagination count={pagination?.pageCount} page={selectedPage} onChange={handlePagginationChange} />
                         :  null }
                    </div>
                </div>
            </ThemeProvider>
        </>
    )
}

